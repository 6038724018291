import styled from "styled-components";
import { remCalc } from "../../../theme/utils";

const HyperAnchor = styled.a`
  display: inline-block;
  color: ${props => props.theme.colors.brand500};
  transition: 0.3s ease-in;
  text-decoration: none;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${remCalc(2)};
    background-color: ${props => props.theme.colors.brand100};
    transition: 0.15s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: ${props => props.theme.colors.brand700};

    &::after {
      height: calc(100% - ${remCalc(2)});
    }
  }
`;

export default HyperAnchor;
