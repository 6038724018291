import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { remCalc } from "../../../theme/utils";

const StyledButton = styled.button`
  border: none;
  border-radius: ${remCalc(2)};
  font-weight: 500;
  letter-spacing: ${remCalc(0.8)};
  font-size: ${remCalc(16)};
  font-family: "Gilroy", Arial, Helvetica, sans-serif;
  padding: ${props => props.theme.spacing.xs + " " + props.theme.spacing.xl};
  transition: 0.3s ease-in-out;

  ${props =>
    props.kind === "primary"
      ? css`
          color: ${props.theme.colors.brand100};
          background-color: ${props.theme.colors.brand500};
        `
      : css`
          color: ${props.theme.colors.brand500};
          background-color: ${props.theme.colors.white};
          border: 1px solid ${props.theme.colors.brand500};
        `};

  &:hover {
    cursor: pointer;
    ${props =>
      props.kind === "primary"
        ? css`
            background-color: ${props.theme.colors.brand400};
          `
        : css`
            background-color: ${props.theme.colors.brand100};
          `}
  }
`;

const Button = props => {
  const { children, onClick } = props;

  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  kind: "primary",
};

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf(["primary", "secondary"]),
  type: PropTypes.oneOf(["button", "reset", "submit"]),
};

export default Button;
