import PropTypes from "prop-types";
import styled from "styled-components";
import { generateResponsiveStyles } from "../../../theme/utils";

const FlexItem = styled.div`
  flex: ${props => props.flex};
  flex-grow: ${props => props.grow};
  flex-shrink: ${props => props.shrink};
  flex-basis: ${props => props.basis};
  height: ${props => props.height};
  order: ${props => props.order};
  ${props => generateResponsiveStyles("span", props.span)};
`;

FlexItem.defaultProps = {
  span: 12,
};

FlexItem.propTypes = {
  basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  grow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shrink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FlexItem;
