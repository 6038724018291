import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Stack = styled.div`
  > * + * {
    ${props =>
      props.direction === "vertical"
        ? css`
            margin-top: ${props => props.theme.spacing[props.gap] || props.gap};
          `
        : css`
            margin-left: ${props =>
              props.theme.spacing[props.gap] || props.gap};
          `}
  }
`;

Stack.defaultProps = {
  gap: "md",
  direction: "vertical",
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  gap: PropTypes.string,
};

export default Stack;
