import React from "react";
import Layout from "../components/common/Layout/layout";
import SEO from "../components/common/Seo/seo.js";
import Flex from "../components/common/Flex";
import FlexItem from "../components/common/Flex/flex-item";
import IconButton from "../components/common/Button/icon-button";
import Box from "../components/common/Box";
import Text from "../components/common/Text";
import HyperAnchor from "../components/common/HyperAnchor";
import Stack from "../components/common/Stack";
import GridContainer from "../components/common/Grid/grid-container";

const About = props => {
  return (
    <Layout>
      <SEO title="About" />
      <Box
        as="main"
        id="main"
        textAlign={["left", "center"]}
        marginTop="lg"
        marginBottom="lg"
      >
        <GridContainer as="section" height="100%">
          <Flex
            direction="column"
            height="100%"
            align="center"
            justify="center"
          >
            <FlexItem span={[12, 9, 7]}>
              <Stack gap="md">
                <Text size="large">
                  I’m{" "}
                  <Text size="large" as="span" weight={600}>
                    Samuel Akintunde
                  </Text>
                  , a frontend developer and designer based in Lagos, Nigeria.
                </Text>
                <Text size="large">
                  About 2 years of experience working at{" "}
                  <HyperAnchor href="https://www.witts-stratts.com">
                    Witts & Stratts Media
                  </HyperAnchor>{" "}
                  on amazing projects such as{" "}
                  <HyperAnchor href="https://etaht.org">ETAHT</HyperAnchor>,{" "}
                  <HyperAnchor href="https://www.asharamisynergy.com">
                    Asharami Synergy
                  </HyperAnchor>{" "}
                  and{" "}
                  <HyperAnchor href="https://www.tnp.com.ng">TNP</HyperAnchor>.
                </Text>
                <Text size="large">
                  Works with Javascript(Vanilla, React, Gatsby, Next, Node),
                  Sass, Styled Components, Dart and Flutter. Currently digging
                  deeper into React and accessibility. Interested in trying out
                  Golang.
                </Text>
                <Text size="large">
                  Contributing to{" "}
                  <HyperAnchor href="https://github.com/Say-Their-Name">
                    Say Their Names
                  </HyperAnchor>
                  , a platform that raises awareness of the injustice and often
                  forgotten names of racial inequality at the hands of law
                  enforcement.
                </Text>
                <Text size="large">
                  Available for contracts and part-time projects.
                </Text>
              </Stack>
              <Box marginTop={["xl", "xxl"]}>
                <Flex justify="center" wrap>
                  <HyperAnchor href="mailto:hello@samakintunde.com">
                    <IconButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g className="nc-icon-wrapper">
                          <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z" />
                        </g>
                      </svg>
                      <span>Send Message</span>
                    </IconButton>
                  </HyperAnchor>
                  <Box width="1rem"></Box>
                  <HyperAnchor
                    href="/files/Resume-SamuelAkintunde.pdf"
                    download="Resume-SamuelAkintunde"
                  >
                    <IconButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g className="nc-icon-wrapper">
                          <path d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15-2.148.642-3.757 2.67-3.757 4.85 0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.757 2.673-3.016l.581-.102.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888z" />
                          <path d="M13.004 14v-4h-2v4h-3l4 5 4-5z" />
                        </g>
                      </svg>
                      <span>Download Resume</span>
                    </IconButton>
                  </HyperAnchor>
                </Flex>
              </Box>
            </FlexItem>
          </Flex>
        </GridContainer>
      </Box>
    </Layout>
  );
};

About.propTypes = {};

export default About;
